import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import Script from 'next/script';
import router, { useRouter } from 'next/router';
import UserManager from '../auth/userManager';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { headerScriptsApi } from '../apiStrapi/StrapiApiService';
import { HeaderScripts } from '../apiStrapi/models/contentType/headerScripts';
import App from 'next/app'
import {GoogleOAuthProvider} from "@react-oauth/google";

const cmsLmsScripts = `
  <html>
    <head>
      <!-- Cookie Consent by https://www.cookiewow.com -->
      <script type="text/javascript" src="https://cookiecdn.com/cwc.js"></script>
      <script id="cookieWow" type="text/javascript" src="https://cookiecdn.com/configs/WoEKLD6cntSeFAvWYybtJmfW" data-cwcid="WoEKLD6cntSeFAvWYybtJmfW"></script>

      <!-- Google Tag Manager -->
      <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5L74DFN');</script>
      <!-- End Google Tag Manager -->

      <!-- Segment -->
      <script>
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="sKASuDRjESb9JARCW8UuRLf4BkIpnJq5";;analytics.SNIPPET_VERSION="4.15.3";
        analytics.load("sKASuDRjESb9JARCW8UuRLf4BkIpnJq5");
        analytics.page();
        }}();
      </script>
      <!-- End Segment -->
    </head>
    <body>
      <!-- Google Tag Manager (noscript) -->
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5L74DFN"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->
    </body>
  </html>
`;

type CustomAppProps = AppProps & {
  headerScript: HeaderScripts
}

function MyApp({ Component, pageProps: { session, ...pageProps }, headerScript }: CustomAppProps) {

  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => {
       // @ts-ignore window.analytics undefined below
      window.analytics.page(url);
      document?.getElementById("__next")?.scrollIntoView();
    });
    if (headerScript) {
      const head = document?.getElementsByTagName('head')[0];
      head.innerHTML += headerScript?.attributes?.scripts
    }
  }, []);

  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: cmsLmsScripts }} />
      <Script src="https://kit.fontawesome.com/bce3f035e8.js" crossOrigin="anonymous" />
      <MessengerCustomerChat pageId='112534107540691' appId={process.env.NEXT_PUBLIC_NEXTAUTH_FACEBOOK_CLIENT_ID} />
        <Auth>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_NEXTAUTH_GOOGLE_CLIENT_ID as string}>
            <Component {...pageProps} key={typeof window !== 'undefined' ? router.asPath: undefined} />
          </GoogleOAuthProvider>
        </Auth>
    </React.Fragment>
  )
}
MyApp.getInitialProps = async (context) => {
  const pageProps = await App.getInitialProps(context);
  const data  = await headerScriptsApi()
  return {
    ...pageProps,
    headerScript: data
  }
}

function Auth({ children }: { children: JSX.Element }) {
  const router = useRouter();
  const { asPath } = router
  const userManager = new UserManager();
  const protectPath = [
    "/library",
    "/account",
    "/product",
    "/summary-document",
    "/communication-page",
    "/guard-contact",
    "/free-trial",
    "/renewal",
  ];
  const isNotAllowed = typeof window !== 'undefined'
                        && !userManager.isLoggedIn()
                        && Boolean(protectPath.find(value => {
                            return window.location.pathname.includes(value)
                          }));
  useEffect(() => {
    if (isNotAllowed) {
      router.push({
        pathname: asPath === '/free-trial/' ? '/register' : '/login',
        query: {
          redirect: asPath
        }
      });
    }
  })
  if (isNotAllowed) {
    return <div></div>;
  }
  return children;
}

export default MyApp
