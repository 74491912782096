import {
  AccountContent,
  CheckCoursePurchasedResponse,
  PostContactGuard,
  ProfileImageBody,
  UpdateUserProfileProps,
} from "./models/content/account";
import {
  NEST_HEADERs,
  NEST_API_URLS,
  nestHeaderAuth,
  nestRefreshHeaderAuth,
} from "./models/contact";
import {
  Auth,
  AuthApiProps,
  AuthUser,
  RefreshAuthApiProps,
} from "./models/content/auth";
import UserManager from "../auth/userManager";
import router from "next/router";
import {
  CourseLMS,
  OnGoingEpisodes,
  Episodes,
  NextInstructorFormProps,
  CourseAndEpIds,
} from "./models/content/courseLms";
import {
  LastWatchedEp,
  MyCourseContent,
  WatchedEpisodeResponse,
} from "./models/content/myCourse";
import { MySubscription } from "./models/content/mySubscription";
import { message } from "antd";
import { RegisterApiProps, RegisterContent } from "./models/content/register";
import {
  ChangePasswordApiProps,
  CheckPasswordProps,
  CheckPasswordResponse,
  EmailPasswordApiProps,
  ForgotPasswordProps,
  ResetPasswordProps,
} from "./models/content/password";
import axios from "axios";

export async function loginApi(body: AuthApiProps) {
  body.device = navigator.userAgent;
  try {
    const response = await fetch(NEST_API_URLS.auth, {
      method: "POST",
      headers: NEST_HEADERs.default,
      body: JSON.stringify(body),
    });
    return (await response.json()) as Auth;
  } catch (error) {
    console.log(error);
  }
}

export async function refreshTokenApi(
  body: RefreshAuthApiProps
): Promise<Auth> {
  const url = NEST_API_URLS.auth + "/refresh";
  const response = await fetch(url, {
    method: "POST",
    headers: nestRefreshHeaderAuth(),
    body: JSON.stringify(body),
  });
  localStorage.getItem("refreshToken") && response.status === 401
    ? router
        .replace("/")
        .then(() => {})
        .then(() => {
          new UserManager().destroyToken();
        })
    : {};
  return (await response.json()) as Auth;
}

export async function loginOrCreateApi(body: any) {
  try {
    const response = await fetch(NEST_API_URLS.partialLogin, {
      method: "POST",
      headers: NEST_HEADERs.default,
      body: JSON.stringify(body),
    });
    return (await response.json()) as any;
  } catch (error) {
    console.log(error);
  }
}

export async function getGoogleAuthToken(body: any) {
  body.device = navigator.userAgent;
  try {
    const response = await fetch(NEST_API_URLS.googleAuth, {
      method: "POST",
      headers: NEST_HEADERs.default,
      body: JSON.stringify(body),
    });
    return (await response.json()) as Auth;
  } catch (error) {
    console.log(error);
  }
}

export async function getFacebookAuthToken(body: any) {
  body.device = navigator.userAgent;
  try {
    const response = await fetch(NEST_API_URLS.facebookAuth, {
      method: "POST",
      headers: NEST_HEADERs.default,
      body: JSON.stringify(body),
    });
    return (await response.json()) as Auth;
  } catch (error) {
    console.log(error);
  }
}

export async function logoutUser(): Promise<string> {
  global.analytics.track("Logout");
  try {
    const response = await fetch(NEST_API_URLS.saveUserLogout, {
      method: "POST",
      headers: await nestHeaderAuth(),
      body: JSON.stringify({ device: navigator.userAgent }),
    });
    return (await response.json()) as string;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export async function validateTokenApi() {
  try {
    const response = await fetch(NEST_API_URLS.auth, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as AuthUser;
  } catch (error) {
    console.log(error);
  }
}

export async function checkContactGuardApi() {
  try {
    const response = await fetch(NEST_API_URLS.checkContactGuard, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as AccountContent;
  } catch (error) {
    console.log(error);
  }
}

export async function checkCoursePurchasedApi(id: number) {
  try {
    const response = await fetch(
      NEST_API_URLS.checkCoursePurchased + `/${id}`,
      {
        method: "GET",
        headers: await nestHeaderAuth(),
      }
    );
    return (await response.json()) as CheckCoursePurchasedResponse;
  } catch (error) {
    console.log(error);
  }
}

export async function checkPasswordApi(body: CheckPasswordProps) {
  try {
    const response = await fetch(NEST_API_URLS.checkPassword, {
      method: "POST",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(body),
    });
    return (await response.json()) as CheckPasswordResponse;
  } catch (error) {
    console.log(error);
  }
}

export async function postContactGuardApi(body: PostContactGuard) {
  let bodyLocal = Object.assign({}, body);
  delete bodyLocal.id;
  try {
    const response = await fetch(
      NEST_API_URLS.contactGuard + "/create-contact",
      {
        method: "POST",
        headers: await nestHeaderAuth(),
        body: JSON.stringify(bodyLocal),
      }
    );
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function patchContactGuardApi(body: PostContactGuard) {
  let bodyLocal = Object.assign({}, body);
  delete bodyLocal.id;
  try {
    const response = await fetch(`${NEST_API_URLS.contactGuard}/${body.id}`, {
      method: "PATCH",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(bodyLocal),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function allCourseLmsApi() {
  try {
    const response = await fetch(NEST_API_URLS.courseLms, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as CourseLMS[];
  } catch (error) {
    console.log(error);
  }
}

export async function getEpisodesAndQuiz(id: string) {
  try {
    const response = await fetch(
      NEST_API_URLS.courseLms + `/${id}/get-episode-and-quiz`,
      {
        method: "POST",
        headers: await nestHeaderAuth(),
      }
    );
    return (await response.json()) as CourseLMS;
  } catch (error) {
    console.log(error);
  }
}

export async function getWatchedEpisodes(
  courseID: number | null
): Promise<WatchedEpisodeResponse> {
  const blankResponse = { watchedEpisode: [] };
  if (!courseID) {
    return blankResponse;
  }
  try {
    const response = await fetch(
      NEST_API_URLS.watchedEpisode + `?course=${courseID}`,
      {
        method: "GET",
        headers: await nestHeaderAuth(),
      }
    );
    return (await response.json()) as WatchedEpisodeResponse;
  } catch (error) {
    console.log(error);
    return blankResponse;
  }
}

export async function saveWatchedEpisode(data: {
  course_id: number;
  episode_id: number;
}): Promise<void> {
  try {
    await fetch(NEST_API_URLS.watchedEpisode, {
      method: "POST",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(data),
    });
    localStorage.removeItem("lastSecond");
  } catch (error) {
    console.log(error);
  }
}

export async function saveLastSecondOfEpisode() {
  const courseID = localStorage.getItem("courseID");
  const episodeID = localStorage.getItem("episodeID");
  const lastSecond = localStorage.getItem("lastSecond");
  const episodeCompletionPercentage = localStorage.getItem(
    "episodeCompletionPercentage"
  );
  if (!courseID || !episodeID || !lastSecond) {
    console.log("Cannot Save Last Second");
    !courseID && console.log("Course ID is Missing");
    !episodeID && console.log("Episode ID is Missing");
    !lastSecond && console.log("Last Second is Missing");
    !episodeCompletionPercentage &&
      console.log("Episode Percentage is Missing");
    return;
  }
  const data = {
    course_id: +courseID,
    episode_id: +episodeID,
    last_second: lastSecond,
    episodeCompletionPercentage: episodeCompletionPercentage,
  };
  try {
    fetch(NEST_API_URLS.onGoingEpisode, {
      method: "POST",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(data),
    }).then(
      () => {
        localStorage.removeItem("lastSecond");
        console.log("Can Save Successfully");
      },
      (err) => {
        console.error(err);
      }
    );
  } catch (error) {
    console.log(error);
  }
}

export async function saveFirstWatched() {
  const courseID = localStorage.getItem("courseID");
  const episodeID = localStorage.getItem("episodeID");
  const refreshToken = localStorage.getItem("refreshToken");
  if (!courseID || !episodeID || !refreshToken) {
    console.log("Cannot Save First Watched");
    !courseID && console.log("Course ID is Missing");
    !episodeID && console.log("Episode ID is Missing");
    return;
  }
  const data = {
    course_id: +courseID,
    episode_id: +episodeID,
  };
  try {
    const response = await fetch(NEST_API_URLS.saveFirstWatch, {
      method: "POST",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(data),
    });
    return null;
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getOnGoingEpisodesForCourse(
  courseID: number
): Promise<OnGoingEpisodes[]> {
  try {
    const response = await fetch(
      NEST_API_URLS.onGoingEpisode + `?course=${courseID}`,
      {
        method: "GET",
        headers: await nestHeaderAuth(),
      }
    );
    return (await response.json()) as OnGoingEpisodes[];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCourseAndEpIDsBySlug(
  slug: string
): Promise<CourseAndEpIds | null> {
  try {
    const response = await fetch(NEST_API_URLS.getCourseBySlug + `/${slug}`, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as CourseAndEpIds;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getPDF(id: number) {
  try {
    const response = await fetch(NEST_API_URLS.getPdf + `${id}`, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as { data: ArrayBufferLike; type: string };
  } catch (error) {
    console.log(error);
  }
}

export async function episodeApi(id: string) {
  try {
    const response = await fetch(NEST_API_URLS.episode + `/${id}`, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as Episodes;
  } catch (error) {
    console.log(error);
  }
}

export async function getSignedToken(id: string) {
  try {
    const response = await fetch(NEST_API_URLS.getSignedToken + `/${id}`, {
      method: "GET",
    });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function forgotPasswordApi(body: ForgotPasswordProps) {
  try {
    const response = await fetch(NEST_API_URLS.forgotPassword, {
      method: "POST",
      headers: NEST_HEADERs.default,
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function coursesFreeTrialApi() {
  try {
    const response = await fetch(NEST_API_URLS.freetrial, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as CourseLMS[];
  } catch (error) {
    console.log(error);
  }
}

export async function getEpisodes(id: string) {
  try {
    const response = await fetch(NEST_API_URLS.courseLms + `/${id}`, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as CourseLMS;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserProfileApi(): Promise<AccountContent | null> {
  try {
    const response = await fetch(NEST_API_URLS.auth, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response?.json()) as AccountContent;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function myCourseApi() {
  try {
    const response = await fetch(NEST_API_URLS.myCourse, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as MyCourseContent;
  } catch (error) {
    console.log(error);
  }
}

export async function myRecommendedCourseApi(
  userId: string | null,
  courseId: number | null
) {
  const blankResponse = { watchedEpisode: [] };
  if (!userId || !courseId) {
    return blankResponse;
  }
  try {
    const response = await fetch(
      NEST_API_URLS.myRecommendedCourse +
        `?userId=${userId}&courseId=${courseId}
      `,
      {
        method: "GET",
        headers: await nestHeaderAuth(),
      }
    );
    return await response.json();
  } catch (error) {
    return blankResponse;
  }
}

export async function submitEvaluation(body: {
  score: number | null;
  feedback: string;
  course: number;
}) {
  try {
    const response = await fetch(NEST_API_URLS.courseEvaluation, {
      method: "POST",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function getLastWatchedEpisode(): Promise<LastWatchedEp | null> {
  try {
    const response = await fetch(NEST_API_URLS.lastWatchedEpisode, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as LastWatchedEp;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getCertificate(id: number): Promise<any> {
  try {
    const response = await fetch(`${NEST_API_URLS.getCertificate}/${id}`, {
      method: "GET",
      headers: await nestHeaderAuth(),
    })
      .then(async (res) => {
        return await res.blob();
      })
      .catch((err) => err);
    return await response;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function mySubscriptionApi() {
  try {
    const response = await fetch(NEST_API_URLS.mySubscription, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as MySubscription[];
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function mySubscriptionExpiredApi() {
  try {
    const response = await fetch(NEST_API_URLS.mySubscriptionExpired, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return (await response.json()) as any[];
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function createNextInstrustorFormApi(
  body: NextInstructorFormProps
) {
  try {
    const response = await fetch(NEST_API_URLS.nextInstructorName, {
      method: "POST",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(body),
    });
    message.success("Successfully Sent");
    return await response.json();
  } catch (error) {
    console.log(error);
    message.error("Error, fail to save data");
  }
}

export async function ProfileImageApi(id: number, body: ProfileImageBody) {
  const formData = new FormData();
  formData.append("profile_image", body.profile_image, body.profile_image.name);
  try {
    const response = await fetch(NEST_API_URLS.users + `/${id}/profile_image`, {
      method: "POST",
      headers: await nestHeaderAuth(true),
      body: formData,
    });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function registerApi(body: RegisterApiProps) {
  try {
    const response = await fetch(NEST_API_URLS.users, {
      method: "POST",
      headers: NEST_HEADERs.default,
      body: JSON.stringify(body),
    });
    return (await response.json()) as RegisterContent;
  } catch (error) {
    console.log(error);
  }
}

export default async function resetPasswordApi(body: ResetPasswordProps) {
  try {
    const response = await fetch(NEST_API_URLS.resetPassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return (await response.json()) as RegisterContent;
  } catch (error) {
    console.log(error);
  }
}

export async function changePasswordApi(body: ChangePasswordApiProps) {
  const response = await fetch(NEST_API_URLS.resetPasswordContact, {
    method: "POST",
    body: JSON.stringify(body),
    headers: await nestHeaderAuth(),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
  return response;
}

export async function resetPasswordWithEmail(body: EmailPasswordApiProps) {
  const data = {
    password: body.password,
    passwordConfirmation: body.passwordConfirmation,
    verificationString: body.verificationString,
  };
  const response = await fetch(NEST_API_URLS.resetPassword, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
  return response;
}

export function sendEmail(id: number) {
  fetch(NEST_API_URLS.sendEmail + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    res.status !== 200 && alert("Cannot Send Email, Please Contact Support.");
  });
}

function removeProperty(obj: UpdateUserProfileProps) {
  let objLocal: UpdateUserProfileProps = obj;
  delete objLocal.id;
  delete objLocal.createDate;
  delete objLocal.updateDate;
  delete objLocal.deletedAt;
  return objLocal;
}

export async function updateUserProfileApi(
  body: UpdateUserProfileProps,
  id: number
) {
  try {
    const response = await fetch(NEST_API_URLS.users + `/${id}`, {
      method: "PATCH",
      headers: await nestHeaderAuth(),
      body: JSON.stringify(removeProperty(body)),
    });
    return (await response.json()) as UpdateUserProfileProps;
  } catch (error) {
    console.log(error);
  }
}

export async function submitAssessments(data) {
  return await axios.post(NEST_API_URLS.assessmentResponse, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function getAssessmentResult(id: number) {
  return await axios.get(`${NEST_API_URLS.assessmentResult}/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function getQuestionByEpisode(params: string) {
  return await axios.get(NEST_API_URLS.getQuestionByEpisode + params, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function saveAssessmentResults(data) {
  return await axios.post(NEST_API_URLS.assessmentResult, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function getUserTaggings() {
  try {
    const response = await fetch(NEST_API_URLS.getUserTags, {
      method: "GET",
      headers: await nestHeaderAuth(),
    });
    return await response.json();
  } catch (error) {
    console.log(error);
    return null;
  }
}
