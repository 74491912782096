import { NextRouter } from 'next/router';
import { getUserProfileApi } from "../apiNest/NestApiService";
import {notification} from "antd";
import {AccountContent, segmentSubscription} from "../apiNest/models/content/account";

export default class UserManager {
    tokenKey: string = "token";
    profileImage: string = "profile";
    userId: string = "uid";
    bought_annual: boolean = false;
    tokenSavedTime: string = "tokenSavedTime"
    refreshTokenKey: string = "refreshToken";

    constructor() { }

    async saveToken(token: string, refreshToken: string): Promise<AccountContent | null> {
        await localStorage.setItem(this.tokenKey, token);
        await localStorage.setItem(this.refreshTokenKey, refreshToken);
        await localStorage.setItem(this.tokenSavedTime, (new Date().getTime()/1000).toString());
        const user = await getUserProfileApi();
        if (!user) {
            return null;
        }
        const contact = { ...user.contact }
        let subscription_reconds: segmentSubscription[] = [];
        if (user.subscription) {
            await user.subscription.forEach(eachSubscription => {
                const data = {
                    starting_date: eachSubscription.starting_date,
                    access_group_name: eachSubscription.access_group.name
                }
                subscription_reconds.push(data);
            });
        }
        delete user.subscription;
        delete user.contact;
        delete contact.id ;
        delete contact.createdDate;
        global.analytics.track('Login')
        await global.analytics.identify(user.id, {
          ...user,
        ...contact,
        subscription: subscription_reconds
        });
        this.bought_annual = user.bought_annual || false;
        await localStorage.setItem("boughtAnnual",this.bought_annual.toString())
        await this.saveProfileImage(user.profile_image);
        await this.saveUserId(user.id);
        return user;
    }

    getTokenSavedTime(): string | null {
        if (typeof window === 'undefined') {
            return null;
        }
        return localStorage.getItem(this.tokenSavedTime);
    }

    getToken(): string | null {
        if (typeof window === 'undefined') {
            return null;
        }
        return localStorage.getItem(this.tokenKey);
    }

    getJwtToken(): string {
        if (typeof window === 'undefined') {
            return '';
        }
        return "Bearer " + localStorage.getItem(this.tokenKey);
    }

    getHeaderRefreshToken(): string {
        if (typeof window === 'undefined') {
            return '';
        }
        return "Bearer " + localStorage.getItem(this.refreshTokenKey);
    }

    destroyToken(): void {
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem(this.tokenSavedTime);
        localStorage.removeItem(this.refreshTokenKey);
        localStorage.removeItem("boughtAnnual");
        this.deleteUserId();
        this.deleteProfileImage();
    }

    isLoggedIn(): boolean {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem(this.tokenKey);
            return Boolean( token && token !== 'undefined');
        }
        return false;
    }

    hasAnnual(): boolean {
        const bought_annual = localStorage.getItem("boughtAnnual") === "true";
        return bought_annual;
    }

    saveProfileImage(profileImage: string): void {
        profileImage && localStorage.setItem(this.profileImage, profileImage);
    }

    getProfileImage(): string | null {
        if (typeof window === 'undefined') {
            return null;
        }
        return localStorage.getItem(this.profileImage) || null;
    }

    deleteProfileImage(): void {
        localStorage.removeItem(this.profileImage);
    }

    saveUserId(id: number): void {
        localStorage.setItem(this.userId, id.toString());
    }

    getUserId(): string | null {
        if (typeof window === 'undefined') {
            return null;
        }
        return localStorage.getItem(this.userId);
    }

    deleteUserId(): void {
        localStorage.removeItem(this.userId);
    }

    async redirectCheckout(router: NextRouter, checkoutUrl: string) {
        if (!checkoutUrl) {
            notification['error']({ message: 'Missing Checkout Url' });
            return;
        }
        const userID = Buffer.from(this.getUserId()?.toString() || "", 'utf8').toString('base64');
        router.push(`${checkoutUrl}${checkoutUrl.includes('?') ? "&" : "?"}cid=${userID}`);
    }
}
