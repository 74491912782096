import UserManager from "../../auth/userManager";
import { refreshTokenApi } from "../NestApiService";
import { RefreshAuthApiProps } from "./content/auth";

const BASE_URL =
  process?.env?.NEXT_PUBLIC_NEST_API || "https://nestjs-dev.cariber.co";
const BASE_API = BASE_URL + "/api";

export const NEST_API_URLS = {
  auth: BASE_API + "/auth",
  facebookAuth: BASE_API + "/facebook-auth",
  googleAuth: BASE_API + "/google-auth",
  myCourse: BASE_API + "/users/my-course",
  myRecommendedCourse: BASE_API + "/users/my-recommended-course",
  checkContactGuard: BASE_API + "/users/contact-guard",
  partialLogin: BASE_API + "/users/partial-login",
  resetPassword: BASE_API + "/users/reset-password",
  resetPasswordContact: BASE_API + "/users/reset-password-contact",
  users: BASE_API + "/users",
  contactGuard: BASE_API + "/contact",
  courseLms: BASE_API + "/course",
  episode: BASE_API + "/episode",
  mySubscription: BASE_API + "/my-subscription",
  checkPassword: BASE_API + "/users/check-password",
  courseEvaluation: BASE_API + "/course-evaluation",
  forgotPassword: BASE_API + "/users/forgot-password",
  watchedEpisode: BASE_API + "/users/watched-episode",
  onGoingEpisode: BASE_API + "/users/on-going-episode",
  getCourseBySlug: BASE_API + "/course/get-course-by-slug",
  sendEmail: BASE_API + "/users/set-password-old-user",
  checkCoursePurchased: BASE_API + "/users/check/course",
  lastWatchedEpisode: BASE_API + "/users/last-watched-episode",
  getCertificate: BASE_API + "/media/get-certificate",
  getQuestionByEpisode: BASE_API + "/assessment/get-questions-by-episode",
  assessmentResponse: BASE_API + "/assessment-response",
  assessmentResult: BASE_API + "/assessment-result",
  getAssessmentByCourse: BASE_API + "/assessment/get-assessment-by-course",
  saveFirstWatch: BASE_API + "/users/save-first-watched",
  getSignedToken: BASE_API + "/cloudflare/get-signed-token",
  nextInstructorName: BASE_API + "/next-instructor-form",
  saveUserLogout: BASE_API + "/auth/save-logout-user",
  mySubscriptionExpired: BASE_API + "/my-subscription/expired",
  freetrial: BASE_API + "/free-trial",
  getPdf: BASE_API + "/course/pdf/",
  getUserTags: BASE_API + "/users/user-tags",
};

export const NEST_HEADERs = {
  default: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

export function nestRefreshHeaderAuth(): HeadersInit {
  return {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: new UserManager().getHeaderRefreshToken(),
  };
}

//check deploy

export async function nestHeaderAuth(onlyAuth?: Boolean): Promise<HeadersInit> {
  const userManager = new UserManager();
  if (onlyAuth) {
    return {
      Authorization: userManager.getJwtToken(),
    };
  }
  const tokenSavedTime = userManager.getTokenSavedTime();
  const tokenSavedTimeDifference =
    new Date().getTime() / 1000 - Number(tokenSavedTime);
  // check if the current token saving time is over 1 day
  if (tokenSavedTimeDifference > 86400) {
    // call refresh token API after access token is expired
    const userId = userManager.getUserId();
    const refreshToken = userManager.getHeaderRefreshToken();
    const formData: RefreshAuthApiProps = {
      userId: Number(userId),
      refreshToken: refreshToken.replace("Bearer ", ""),
    };
    const data = await refreshTokenApi(formData);
    await userManager.saveToken(data.access_token, data.refresh_token);
  }
  return {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: userManager.getJwtToken(),
  };
}
